/* FONT IMPORTS */

@import url(https://fonts.googleapis.com/css?family=Open+Sans:300, 400, 700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.bigCalendarWrap {
  background: #fff;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s ease-in all;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  opacity: 0;
  display: none;
}
.iosCalendar {
  opacity: 1;
  z-index: 1;
  /* transform: scale(1); */
  transition: 0.3s ease-in all;
}

.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: rgb(0, 0, 0);
  --text-color-light: rgb(121, 121, 122);
  --border-color: #999;
  --border-color-header: rgb(0, 0, 0);
  --border-color-calendar: rgb(0, 0, 0);
  --bg-color: #eee;
  --neutral-color: #fff;
}

/* GENERAL */

* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: var(--text-color);
  background: var(--bg-color);
  position: relative;
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid var(--border-color-header);
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: 0.25em;
}
.topArrowWrap {
  display: flex;
  background-color: rgb(243, 242, 242);
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
}
.topArrowWrap p,
.topArrowWrap span {
  display: block;
  padding-left: 20px;
  width: 100%;
  font-size: 16px;
  line-height: 30px;
  color: rgb(248, 98, 98);
}
.topArrowCalendar {
  font-weight: 600;
  height: 30px;
  width: 30px;
  position: relative;
}
.topArrowCalendar::before {
  content: "";
  background-color: rgb(248, 98, 98);
  height: 18px;
  width: 2px;
  transform: rotate(135deg);
  position: absolute;
  left: 15px;
  top: 11px;
}
.topArrowCalendar::after {
  content: "";
  background-color: rgb(248, 98, 98);
  height: 18px;
  width: 2px;
  transform: rotate(45deg);
  position: absolute;
  left: 15px;
  top: 0px;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  background: #eee;
}

.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}
.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: 0.75em 0;
}
.monthNameBigCalendar {
  padding: 5px;
  text-transform: capitalize;
  font-weight: bold;
}
.animationRow {
  transition: 0.5s ease height;
}
.calendarDayDescription {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}
.animationMonthCalendar {
  transition: 0.5s ease all;
}

.fixedBlocksShow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  opacity: 1;
  transition: 0.4s ease-in all;
  background-color: rgb(243, 242, 242);
}
.fixedBlocksHide {
  position: fixed;
  top: -100px;
  left: 0;
  width: 100%;
  z-index: 2;
  opacity: 1;
  transition: 0.3s ease all;
}
.outline {
  border-top: 2px solid #eee;
  box-sizing: border-box;
  margin: -1px -1px 0 0;
}
.calendar .body .cell {
  position: relative;
  height: 5em;
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}
.animationCalendarBlock {
  width: 33%;
  transition: 0.3s ease height, 0.5s ease width;
}
.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.3s ease-out;
}

.calendar .body .selected .number {
  /* border-bottom: 3px solid red; */
  background-color: red;
  border-radius: 50%;
  display: flex;
  color: #fff;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
  margin-right: -4px;
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 1em;
  font-weight: 700;
}
.calendar .body .disabled {
  opacity: 0;
}
.calendar .body .disabledOpacity {
  color: #eee;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 50px;
  color: var(--main-color);
  opacity: 0;
  font-size: 50px;
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.08;
  transition: 0.5s ease-in;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}
/* ==============IOS=============== */
.headerIos {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 20px;
  border-bottom: 2px solid #eee;
}
.calendar .headerIos .icon {
  padding: 10px;
  cursor: pointer;
  transition: 0.15s ease-out;
}
.calendar .headerIos .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
  color: rgb(248, 98, 98);
}
.headerIosYear {
  padding: 10px;
}
.disabledIos {
  opacity: 0;
}
.calendar .body .cellIos {
  position: relative;
  height: 5em;
  /* border-right: 1px solid var(--border-color); */
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}

.calendar .body .cellIos .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.3em;
  font-weight: 700;
}
.calendar .body .cellIos .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}
.rowIos {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.iosMonth {
  text-transform: capitalize;
  font-weight: bold;
  padding: 0 5px;
}
.calendar .body .selectedIos {
  border-bottom: 3px solid rgb(248, 98, 98);
}
.iosCalendarWrap {
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  position: relative;
  background-color: #fff;
  margin: 5px 10px;
  transition: 0.3s ease height;
}
.iosCalendarWrap:hover {
  transform: scale(1.05);
}
.fakeBg {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(70, 16, 16, 0);
}
