@import url(https://fonts.googleapis.com/css?family=Open+Sans:300, 400, 700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

* {
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-left: 32px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 8px;
  border: 3px solid #fff;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 1, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 1, 0.5, 1) infinite;
  border-color: #f8104d transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.35s;
          animation-delay: -0.35s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.05s;
          animation-delay: -0.05s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.leaflet-container {
  background-color: white;
}

.marker-cluster {
  background-color: rgba(0, 0, 0, 0);
}
.marker-cluster div {
  background-color: #f8104d;
  color: #fff;
  font-weight: bold;
}
.leaflet-div-icon {
  border: none;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0);
}

.leaflet-control-geosearch input {
  font-size: 14px !important;
}
.leaflet-control-geosearch,
.leaflet-control-geosearch ul {
  border-radius: 7px;
  margin: 10px 0 0 50px;
  padding: 5px;
  width: 675px;
  height: auto;
}
.leaflet-touch .leaflet-control-geosearch a.reset {
  height: 50px;
  font-size: 22px;
  display: none;
  justify-content: center;
  align-items: center;
}
.leaflet-touch .leaflet-geosearch-bar form {
  border: 1px solid rgb(211, 211, 211);
  border-radius: 10px;
  overflow: hidden;
}
.leaflet-control-geosearch form {
  padding: 0;
}
.leaflet-control-geosearch .results.active {
  padding: 0;
  border: none;
}
.leaflet-touch .leaflet-geosearch-bar form input {
  height: 50px;
}
.leaflet-container {
  font: 14px Montserrat, Arial, Helvetica, sans-serif;
}
.leaflet-control-geosearch .results.active div {
  border-top: 1px solid rgb(206, 206, 206);
}

@media screen and (max-width: 760px) {
  .leaflet-control-geosearch,
  .leaflet-control-geosearch ul {
    margin: 10px 0 0 10px;
  }
  .leaflet-touch .leaflet-geosearch-bar form {
    width: calc(100vw - 30px);
    top: 0px;
  }
}

.react-awesome-player * {
  outline: 0;
}
.video-js {
  height: auto;
}
.react-awesome-player-container {
  /* position: relative; */
}
.vjs-time-tooltip {
  min-width: 40px;
}
.react-awesome-player-container button {
  outline: none;
}

.vjs-remaining-time {
  visibility: hidden;
}
.vjs-progress-control {
  visibility: hidden;
}

.vjs-button > .vjs-icon-placeholder:before {
  /* line-height: 2; */
}

/* 显示时间 */
.react-awesome-player-container .video-js .vjs-control-bar .vjs-current-time {
  padding: 0;
  display: block !important;
}
.vjs-picture-in-picture-control {
  display: none !important;
}
/* 显示时间 */
.react-awesome-player-container .video-js .vjs-time-divider {
  padding: 0 2px;
  min-width: 0em;
  text-align: center;
  display: block !important;
}

/* 显示时间 */
.react-awesome-player-container .video-js .vjs-control-bar .vjs-duration {
  padding: 0;
  display: block !important;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.vjs-button > .vjs-icon-placeholder:before,
.video-js .vjs-modal-dialog,
.vjs-modal-dialog .vjs-modal-dialog-content {
  position: unset;
}

.react-awesome-player-container video {
  object-fit: fill;
}

.react-awesome-player-container .vjs-fullscreen video {
  object-fit: contain;
}

.react-awesome-player-container .video-layer {
}

.react-awesome-player-container .video-layer section {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #080915;
  box-shadow: 0px 0px 30px 1px #103136 inset;
}

.react-awesome-player-container .video-layer .loader {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.react-awesome-player-container .video-layer .loader-1 .loader-outter {
  position: absolute;
  border: 4px solid #b50136;
  border-left-color: transparent;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41)
    infinite;
  animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

.react-awesome-player-container .video-layer .loader-1 .loader-inner {
  position: absolute;
  border: 4px solid #b50136;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  left: calc(50% - 22px);
  top: calc(50% - 24px);
  border-right: 0;
  border-top-color: transparent;
  -webkit-animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41)
    infinite;
  animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

@-webkit-keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.no-video {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
}

.definition-container {
  width: 30px;
  background: red;
  height: 30px;
  position: relative;
}

.definition-container .definition-btn-container {
  background: #ddd;
  bottom: 30px;
  position: absolute;
  display: none;
}

.vjs-audio-button {
  /* display: none; */
}

.vjs-texttrack-settings {
  /* display: none; */
}

.vjs-menu .vjs-menu-content {
  overflow: visible;
  overflow: initial;
}

.vjs-menu .vjs-menu-content li:first-child {
  display: none;
}

.vjs-menu-content {
  overflow-y: hidden;
}

.video-js
  .vjs-subs-caps-button
  + .vjs-menu
  .vjs-captions-menu-item
  .vjs-menu-item-text
  .vjs-icon-placeholder:before {
  font-family: VideoJS !important;
  content: "\F10D" !important;
}

/* .vjs-icon-subtitles:before,
  .video-js .vjs-subtitles-button .vjs-icon-placeholder:before,
  .video-js .vjs-subs-caps-button .vjs-icon-placeholder:before,
  .video-js.video-js:lang(en-GB)
    .vjs-subs-caps-button
    .vjs-icon-placeholder:before,
  .video-js.video-js:lang(en-IE)
    .vjs-subs-caps-button
    .vjs-icon-placeholder:before,
  .video-js.video-js:lang(en-AU)
    .vjs-subs-caps-button
    .vjs-icon-placeholder:before,
  .video-js.video-js:lang(en-NZ)
    .vjs-subs-caps-button
    .vjs-icon-placeholder:before {
    content: '\e645' !important;
    font-size: 14px;
  } */

/* .vjs-icon-play,
  .video-js .vjs-big-play-button .vjs-icon-placeholder:before,
  .video-js .vjs-play-control .vjs-icon-placeholder {
    font-family: 'iconfont';
  } */

/* .vjs-icon-play:before,
  .video-js .vjs-big-play-button .vjs-icon-placeholder:before,
  .video-js .vjs-play-control .vjs-icon-placeholder:before {
    font-size: 14px;
    content: '\e644' !important;
  } */

/* .vjs-icon-fullscreen-enter:before,
  .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
    font-size: 14px;
    content: '\e646' !important;
  } */

/* .react-awesome-player-container .vjs-icon-play:before,
  .react-awesome-player-container .video-js .vjs-big-play-button .vjs-icon-placeholder:before,
  .react-awesome-player-container .video-js .vjs-play-control .vjs-icon-placeholder:before {
    font-size: 14px;
    content: '\e643' !important;
  } */

/* .vjs-button > .vjs-icon-placeholder:before {
    line-height: 42px;
    font-size: 20px;
  } */

/* .vjs-icon-pause:before,
  .video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
    font-size: 14px;
    content: '\e643' !important;
  } */

.vjs-custom-skin > .video-js .vjs-menu-button-popup .vjs-menu {
  width: 90px;
  left: -30px;
}

.vjs-custom-skin > .video-js .vjs-control {
  width: 30px;
}

.vjs-custom-skin > .video-js .vjs-play-progress,
.vjs-custom-skin > .video-js .vjs-volume-level {
  background-color: #d32f2f;
}

.vjs-custom-skin > .video-js .vjs-loading-spinner {
  border-color: #d32f2f;
}

.vjs-custom-skin > .video-js .vjs-control-bar .vjs-time-control {
  min-width: 6px;
}

.vjs-custom-skin > .video-js .vjs-control-bar .vjs-time-control div {
  height: 42px;
  font-size: 12px;
  line-height: 42px;
}

.vjs-custom-skin > .video-js .vjs-control-bar .vjs-time-control div span {
  line-height: 39px;
}

.vjs-custom-skin > .video-js .vjs-time-control {
  width: auto;
}

.vjs-custom-skin > .video-js .vjs-time-control .vjs-current-time-display {
  width: auto;
}

.vjs-custom-skin .video-js .vjs-big-play-button {
  margin: 0 !important;
  height: 54px !important;
}

.video-js {
  background-color: #212121;
  font-family: VideoJS !important;
  outline: 0;
}

.video-js .vjs-volume-panel {
  position: relative !important;
  left: calc(100% - 80px);
  z-index: 1100;
}

.vjs-live-control {
  background-color: green !important;
  display: none !important;
}
.vjs-current-time,
.vjs-time-divider,
.vjs-duration {
  display: none !important;
  visibility: hidden !important;
}

.video-js .vjs-hover {
  width: 20px !important;
}

.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 54px;
  height: 54px !important;
  border-radius: 50%;
  border: 0;
  line-height: 54px !important;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  line-height: 54px;
  margin: 0 !important;
}

.video-js .vjs-custom-skin > .video-js .vjs-big-play-button {
  margin: 0 !important;
}

/* .video-js .vjs-icon-placeholder {
    line-height: 54px !important;
    font-family: 'iconfont' !important;
  } */

.video-js .vjs-play-progress {
  font-family: VideoJS !important;
}

.video-js .vjs-poster {
  background-size: cover;
}

.video-js:hover .vjs-big-play-button {
  background-color: rgba(0, 0, 0, 0.45);
}

.react-awesome-player-container {
  height: 100% !important;
}
.react-awesome-player-container {
  height: 100% !important;
  display: flex;
  align-items: center;
}
.react-awesome-player-container #vjs_video_3 {
  height: 100%;
}
.react-awesome-player-container:first-child {
  height: 100%;
}

@media screen and (max-width: 760px) {
  .video {
    width: 100%;
    height: 400px;
    border-radius: 5px;
    -webkit-transition: 0.3s ease all;
    transition: 0.3s ease all;
  }
}

@media screen and (max-width: 620px) {
  .video {
    height: 300px;
  }
}
@media screen and (max-width: 500px) {
  .video {
    height: 250px;
  }
}
@media screen and (max-width: 360px) {
  .video {
    height: 160px;
  }
}

input,
textarea {
  -webkit-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
  -webkit-appearance: none;
}

.adminContentMobile {
  display: none;
}

.ReactCrop__image {
  max-height: 300px;
}

.changePhotoInput {
  border: none;
  outline: none;
}
.changePhotoBlock {
  cursor: pointer;
  width: 125px;
}
.changePhotoBlock:active,
.changePhotoBlock:hover,
.changePhotoBlock:focus {
  outline: 0;
  outline-offset: 0;
  border: none;
}
.changePhoto {
  color: #4f4f4f;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  width: 125px;
  cursor: pointer;
  text-align: center;
  padding-top: 12px;
  -webkit-transition: 0.3s ease color;
  transition: 0.3s ease color;
  letter-spacing: 0.5px;
}
.changePhoto:hover {
  color: #f8104d;
}
.profileDataDesc {
  justify-content: space-between;
}

.saveBtnProfile {
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  background-color: #f8104d;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 45px;
  cursor: pointer;
  -webkit-transition: 0.3s ease opacity;
  transition: 0.3s ease opacity;
  margin: 25px 0 0 0;
}

.cancelBtnProfile {
  border: 2px solid #f8104d;
  color: #4f4f4f;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 45px;
  cursor: pointer;
  margin: 25px 0 0 0;
  color: #f8104d;
}
.cancelBtnProfile:hover {
  color: rgb(227, 42, 108);
  border: 2px solid rgb(227, 42, 108);
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
}
.saveBtnProfile:hover {
  opacity: 0.8;
}

.profileDataDesc div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.categoryBtn {
  display: flex;
  height: 50px;
  width: 117px;
  flex-wrap: wrap;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  justify-content: center;
  margin-bottom: 5px;
}
.categoryBtn:last-child {
  margin-right: 0;
}
.categoryBtn:hover {
  background-color: #f8104d;
  color: #fff;
}

.ChooseNewCategory {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  cursor: pointer;
  color: #f8104d;
}

.tableWorkDesc {
  width: 100%;
  border-spacing: 0;
  font-size: 16px;
}

.workTimeTableWrap .tableWorkDesc tr {
  display: flex;
  width: 80%;
}
.workTimeTableWrap .tableWorkDesc tr td {
  margin: 0;
  padding: 10px 15px;
  flex: 1 1;
}
.workTimeTableWrap .tableWorkDesc tr td:first-of-type {
  text-transform: uppercase;
}
.workTimeTableWrap .tableWorkDesc tr .timeTd {
  flex: 3 1;
  font-size: 16px;
  font-weight: normal;
}

.workTimeTableWrap .tableWorkDesc tr .doAsDayOfTd {
  text-align: center;
  color: #f8104d;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  cursor: pointer;
  flex: 3 1;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.workTimeTableWrap .tableWorkDesc tr .doAsDayOfTd:hover {
  color: #000;
}
.workTimeTableWrap h3 {
  padding-bottom: 20px;
  padding-left: 15px;
  font-size: 24px;
}
.streamAdminBlock h3 {
  font-size: 24px;
}
.streamAdminBlock {
  font-size: 18px;
}
.videoWrapAdminDesctop {
  margin-top: 20px;
  width: 80%;
  border-radius: 10px;
  overflow: hidden;
}

.adminContentMobile img {
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
}

.drDownWrap {
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
  overflow: hidden;
  max-height: 0px;
  padding: 0px 20px;
}
.drDownWrapPaddingOff {
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
  padding: 0 !important;
}
.workSchedule table,
.streamSchedule table {
  margin: 20px 0;
  width: 100%;
  border-collapse: collapse;
}
.workSchedule table tr td,
.streamSchedule table tr td {
  font-weight: 400;
  text-align: center;
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  padding: 8px 10px;
  font-size: 16px;
}
.workSchedule table tr td:first-of-type,
.streamSchedule table tr td:first-of-type {
  font-weight: 700;
  font-size: 13px;
}
.workSchedule table tr td:last-of-type,
.streamSchedule table tr td:last-of-type {
  cursor: pointer;
  -webkit-transition: 0.3s ease background-color;
  transition: 0.3s ease background-color;
}
.workSchedule table tr td:last-of-type:hover,
.streamSchedule table tr td:last-of-type:hover {
  background-color: #eee;
}

.uploadFile {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
}

.uploadFile .fileName {
  color: "#999";
}
.uploadFileContainer,
.pickAddress {
  background-color: #fff;
  padding: 5px;
  margin: 5px 0;
  border-radius: 5px;
}
.uploadFileContainer h3,
.pickAddress h3 {
  font-weight: 400;
  font-size: 16px;
  font-weight: 700;
}

.streamAddress {
  border-radius: 5px;
  outline: none;
  border: 1px solid #eee;
  padding: 0 7px;
  flex: 1 1;
  height: 30px;
}

.saveBtn {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #f8104d;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  margin-top: 40px;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  align-self: center;
  width: 235px;
  height: 45px;
  line-height: 45px;
}
.saveBtn:hover {
  opacity: 0.8;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
}
.cancelBtn {
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
  margin-top: 40px;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  align-self: center;
  width: 235px;
  height: 45px;
  line-height: 45px;
  background: #fff;
  color: #c4c4c4;
  border: 2px solid #c4c4c4;
}
.cancelBtn:hover {
  color: #f8104d;
  border-color: #f8104d;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
}

.space {
  display: inline-block;
  width: 60px;
  height: 20px;
}

@media screen and (max-width: 760px) {
  .space {
    display: inline-block;
    width: 0px;
    height: 20px;
  }
}

.MapPopupHeader {
  display: none !important;
}
.successSaved {
  height: 40px;
  position: fixed;
  left: 50%;
  width: 200px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 5px;
  font-weight: 400;
  text-transform: uppercase;
}
@media screen and (max-width: 760px) {
  .successSaved {
    display: none;
  }

  .MapPopupHeader {
    display: flex !important;
  }

  .categoryBtn {
    display: inline-block;
    height: 50px;
    width: 45%;
    border-radius: 5px;
    border: 2px solid #c4c4c4;
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    margin: 8px;
  }
  .categoryBtn:hover {
    background-color: #f8104d;
    color: #fff;
  }

  .adminContentMobile .profileDataDesc {
    margin-top: 0;
    padding: 0 15px;
  }

  .adminContentMobile .saveBtnProfile {
    margin: 10px 0px;
    padding: 20px;
  }

  .adminContentMobile .changePhoto {
    display: flex;
  }
  .adminContentMobile .ReactCrop__image {
    max-height: 250px;
  }
  .adminContentMobile .addressBlockWrapp {
    width: 100%;
  }
  .adminContentMobile .addressText {
    width: 100%;
    display: block;
  }

  .adminContentMobile {
    display: block;
    margin-top: 68px;
    margin-bottom: 65px;
    flex: 1 1;
  }
}

html,
body {
  margin: 0;
}
.bm-menu-wrap {
  width: 100vw;
  top: 0;
}
#App {
  height: 100vh;
}

#page-wrap {
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;
  outline: none;
  border: none;
  color: #000;
  /* Our sidebar item styling */
  /* text-decoration: none; */
  margin-bottom: 10px;
  /* color: #d1d1d1; */
  /* transition: color 0.2s; */
}

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
}

.bm-burger-bars {
  /* background: #373a47; */
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  display: none;
}

.bm-cross {
}

.bm-menu {
  background: #fff;
  padding: 2.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
}

.bm-item-list {
}

.bm-overlay {
  top: 0;
  left: 0;
  background: #fff !important;
}

.img-preview {
  width: 100px;
  height: 100px;
}

/* FONT IMPORTS */

.bigCalendarWrap {
  background: #fff;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 0.3s ease-in all;
  transition: 0.3s ease-in all;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  opacity: 0;
  display: none;
}
.iosCalendar {
  opacity: 1;
  z-index: 1;
  /* transform: scale(1); */
  -webkit-transition: 0.3s ease-in all;
  transition: 0.3s ease-in all;
}

.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: rgb(0, 0, 0);
  --text-color-light: rgb(121, 121, 122);
  --border-color: #999;
  --border-color-header: rgb(0, 0, 0);
  --border-color-calendar: rgb(0, 0, 0);
  --bg-color: #eee;
  --neutral-color: #fff;
}

/* GENERAL */

* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: rgb(0, 0, 0);
  color: var(--text-color);
  background: #eee;
  background: var(--bg-color);
  position: relative;
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid rgb(0, 0, 0);
  border-bottom: 1px solid var(--border-color-header);
  background: #fff;
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: #1a8fff;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: 0.25em;
}
.topArrowWrap {
  display: flex;
  background-color: rgb(243, 242, 242);
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
}
.topArrowWrap p,
.topArrowWrap span {
  display: block;
  padding-left: 20px;
  width: 100%;
  font-size: 16px;
  line-height: 30px;
  color: rgb(248, 98, 98);
}
.topArrowCalendar {
  font-weight: 600;
  height: 30px;
  width: 30px;
  position: relative;
}
.topArrowCalendar::before {
  content: "";
  background-color: rgb(248, 98, 98);
  height: 18px;
  width: 2px;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  position: absolute;
  left: 15px;
  top: 11px;
}
.topArrowCalendar::after {
  content: "";
  background-color: rgb(248, 98, 98);
  height: 18px;
  width: 2px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  left: 15px;
  top: 0px;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: #fff;
  background: var(--neutral-color);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  background: #eee;
}

.calendar .header .icon {
  cursor: pointer;
  -webkit-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
  -webkit-transform: scale(1.75);
          transform: scale(1.75);
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
  color: #1a8fff;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}
.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: rgb(121, 121, 122);
  color: var(--text-color-light);
  font-size: 70%;
  padding: 0.75em 0;
}
.monthNameBigCalendar {
  padding: 5px;
  text-transform: capitalize;
  font-weight: bold;
}
.animationRow {
  -webkit-transition: 0.5s ease height;
  transition: 0.5s ease height;
}
.calendarDayDescription {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}
.animationMonthCalendar {
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

.fixedBlocksShow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  opacity: 1;
  -webkit-transition: 0.4s ease-in all;
  transition: 0.4s ease-in all;
  background-color: rgb(243, 242, 242);
}
.fixedBlocksHide {
  position: fixed;
  top: -100px;
  left: 0;
  width: 100%;
  z-index: 2;
  opacity: 1;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
}
.outline {
  border-top: 2px solid #eee;
  box-sizing: border-box;
  margin: -1px -1px 0 0;
}
.calendar .body .cell {
  position: relative;
  height: 5em;
  overflow: hidden;
  cursor: pointer;
  background: #fff;
  background: var(--neutral-color);
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.animationCalendarBlock {
  width: 33%;
  -webkit-transition: 0.3s ease height, 0.5s ease width;
  transition: 0.3s ease height, 0.5s ease width;
}
.calendar .body .cell:hover {
  background: #eee;
  background: var(--bg-color);
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

.calendar .body .selected .number {
  /* border-bottom: 3px solid red; */
  background-color: red;
  border-radius: 50%;
  display: flex;
  color: #fff;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
  margin-right: -4px;
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 1em;
  font-weight: 700;
}
.calendar .body .disabled {
  opacity: 0;
}
.calendar .body .disabledOpacity {
  color: #eee;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 50px;
  color: #1a8fff;
  color: var(--main-color);
  opacity: 0;
  font-size: 50px;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.08;
  -webkit-transition: 0.5s ease-in;
  transition: 0.5s ease-in;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}
/* ==============IOS=============== */
.headerIos {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 20px;
  border-bottom: 2px solid #eee;
}
.calendar .headerIos .icon {
  padding: 10px;
  cursor: pointer;
  -webkit-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
}
.calendar .headerIos .icon:hover {
  -webkit-transform: scale(1.75);
          transform: scale(1.75);
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
  color: rgb(248, 98, 98);
}
.headerIosYear {
  padding: 10px;
}
.disabledIos {
  opacity: 0;
}
.calendar .body .cellIos {
  position: relative;
  height: 5em;
  /* border-right: 1px solid var(--border-color); */
  overflow: hidden;
  cursor: pointer;
  background: #fff;
  background: var(--neutral-color);
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}

.calendar .body .cellIos .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.3em;
  font-weight: 700;
}
.calendar .body .cellIos .bg {
  font-weight: 700;
  line-height: 1;
  color: #1a8fff;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}
.rowIos {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.iosMonth {
  text-transform: capitalize;
  font-weight: bold;
  padding: 0 5px;
}
.calendar .body .selectedIos {
  border-bottom: 3px solid rgb(248, 98, 98);
}
.iosCalendarWrap {
  cursor: pointer;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  border-radius: 5px;
  position: relative;
  background-color: #fff;
  margin: 5px 10px;
  -webkit-transition: 0.3s ease height;
  transition: 0.3s ease height;
}
.iosCalendarWrap:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.fakeBg {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(70, 16, 16, 0);
}

body,
html,
.App {
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat, sans-serif;
  letter-spacing: 0.5px;
}
a {
  text-decoration: none;
  color: #000;
  font-family: Montserrat, sans-serif;
}

