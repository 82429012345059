body,
html,
.App {
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Montserrat, sans-serif;
  letter-spacing: 0.5px;
}
a {
  text-decoration: none;
  color: #000;
  font-family: Montserrat, sans-serif;
}
