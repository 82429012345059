input,
textarea {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
  -webkit-appearance: none;
}

.adminContentMobile {
  display: none;
}

.ReactCrop__image {
  max-height: 300px;
}

.changePhotoInput {
  border: none;
  outline: none;
}
.changePhotoBlock {
  cursor: pointer;
  width: 125px;
}
.changePhotoBlock:active,
.changePhotoBlock:hover,
.changePhotoBlock:focus {
  outline: 0;
  outline-offset: 0;
  border: none;
}
.changePhoto {
  color: #4f4f4f;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  width: 125px;
  cursor: pointer;
  text-align: center;
  padding-top: 12px;
  transition: 0.3s ease color;
  letter-spacing: 0.5px;
}
.changePhoto:hover {
  color: #f8104d;
}
.profileDataDesc {
  justify-content: space-between;
}

.saveBtnProfile {
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  background-color: #f8104d;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 45px;
  cursor: pointer;
  transition: 0.3s ease opacity;
  margin: 25px 0 0 0;
}

.cancelBtnProfile {
  border: 2px solid #f8104d;
  color: #4f4f4f;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 45px;
  cursor: pointer;
  margin: 25px 0 0 0;
  color: #f8104d;
}
.cancelBtnProfile:hover {
  color: rgb(227, 42, 108);
  border: 2px solid rgb(227, 42, 108);
  transition: 0.3s ease all;
}
.saveBtnProfile:hover {
  opacity: 0.8;
}

.profileDataDesc div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.categoryBtn {
  display: flex;
  height: 50px;
  width: 117px;
  flex-wrap: wrap;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  justify-content: center;
  margin-bottom: 5px;
}
.categoryBtn:last-child {
  margin-right: 0;
}
.categoryBtn:hover {
  background-color: #f8104d;
  color: #fff;
}

.ChooseNewCategory {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  cursor: pointer;
  color: #f8104d;
}

.tableWorkDesc {
  width: 100%;
  border-spacing: 0;
  font-size: 16px;
}

.workTimeTableWrap .tableWorkDesc tr {
  display: flex;
  width: 80%;
}
.workTimeTableWrap .tableWorkDesc tr td {
  margin: 0;
  padding: 10px 15px;
  flex: 1;
}
.workTimeTableWrap .tableWorkDesc tr td:first-of-type {
  text-transform: uppercase;
}
.workTimeTableWrap .tableWorkDesc tr .timeTd {
  flex: 3;
  font-size: 16px;
  font-weight: normal;
}

.workTimeTableWrap .tableWorkDesc tr .doAsDayOfTd {
  text-align: center;
  color: #f8104d;
  transition: 0.3s ease all;
  cursor: pointer;
  flex: 3;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.workTimeTableWrap .tableWorkDesc tr .doAsDayOfTd:hover {
  color: #000;
}
.workTimeTableWrap h3 {
  padding-bottom: 20px;
  padding-left: 15px;
  font-size: 24px;
}
.streamAdminBlock h3 {
  font-size: 24px;
}
.streamAdminBlock {
  font-size: 18px;
}
.videoWrapAdminDesctop {
  margin-top: 20px;
  width: 80%;
  border-radius: 10px;
  overflow: hidden;
}

.adminContentMobile img {
  transition: 0.3s ease all;
}

.drDownWrap {
  transition: 0.4s ease all;
  overflow: hidden;
  max-height: 0px;
  padding: 0px 20px;
}
.drDownWrapPaddingOff {
  transition: 0.4s ease all;
  padding: 0 !important;
}
.workSchedule table,
.streamSchedule table {
  margin: 20px 0;
  width: 100%;
  border-collapse: collapse;
}
.workSchedule table tr td,
.streamSchedule table tr td {
  font-weight: 400;
  text-align: center;
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  padding: 8px 10px;
  font-size: 16px;
}
.workSchedule table tr td:first-of-type,
.streamSchedule table tr td:first-of-type {
  font-weight: 700;
  font-size: 13px;
}
.workSchedule table tr td:last-of-type,
.streamSchedule table tr td:last-of-type {
  cursor: pointer;
  transition: 0.3s ease background-color;
}
.workSchedule table tr td:last-of-type:hover,
.streamSchedule table tr td:last-of-type:hover {
  background-color: #eee;
}

.uploadFile {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
}

.uploadFile .fileName {
  color: "#999";
}
.uploadFileContainer,
.pickAddress {
  background-color: #fff;
  padding: 5px;
  margin: 5px 0;
  border-radius: 5px;
}
.uploadFileContainer h3,
.pickAddress h3 {
  font-weight: 400;
  font-size: 16px;
  font-weight: 700;
}

.streamAddress {
  border-radius: 5px;
  outline: none;
  border: 1px solid #eee;
  padding: 0 7px;
  flex: 1;
  height: 30px;
}

.saveBtn {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #f8104d;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  margin-top: 40px;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  align-self: center;
  width: 235px;
  height: 45px;
  line-height: 45px;
}
.saveBtn:hover {
  opacity: 0.8;
  transition: 0.3s ease all;
}
.cancelBtn {
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
  margin-top: 40px;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  align-self: center;
  width: 235px;
  height: 45px;
  line-height: 45px;
  background: #fff;
  color: #c4c4c4;
  border: 2px solid #c4c4c4;
}
.cancelBtn:hover {
  color: #f8104d;
  border-color: #f8104d;
  transition: 0.3s ease all;
}

.space {
  display: inline-block;
  width: 60px;
  height: 20px;
}

@media screen and (max-width: 760px) {
  .space {
    display: inline-block;
    width: 0px;
    height: 20px;
  }
}

.MapPopupHeader {
  display: none !important;
}
.successSaved {
  height: 40px;
  position: fixed;
  left: 50%;
  width: 200px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 5px;
  font-weight: 400;
  text-transform: uppercase;
}
@media screen and (max-width: 760px) {
  .successSaved {
    display: none;
  }

  .MapPopupHeader {
    display: flex !important;
  }

  .categoryBtn {
    display: inline-block;
    height: 50px;
    width: 45%;
    border-radius: 5px;
    border: 2px solid #c4c4c4;
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    margin: 8px;
  }
  .categoryBtn:hover {
    background-color: #f8104d;
    color: #fff;
  }

  .adminContentMobile .profileDataDesc {
    margin-top: 0;
    padding: 0 15px;
  }

  .adminContentMobile .saveBtnProfile {
    margin: 10px 0px;
    padding: 20px;
  }

  .adminContentMobile .changePhoto {
    display: flex;
  }
  .adminContentMobile .ReactCrop__image {
    max-height: 250px;
  }
  .adminContentMobile .addressBlockWrapp {
    width: 100%;
  }
  .adminContentMobile .addressText {
    width: 100%;
    display: block;
  }

  .adminContentMobile {
    display: block;
    margin-top: 68px;
    margin-bottom: 65px;
    flex: 1;
  }
}
