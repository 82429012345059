.leaflet-control-geosearch input {
  font-size: 14px !important;
}
.leaflet-control-geosearch,
.leaflet-control-geosearch ul {
  border-radius: 7px;
  margin: 10px 0 0 50px;
  padding: 5px;
  width: 675px;
  height: auto;
}
.leaflet-touch .leaflet-control-geosearch a.reset {
  height: 50px;
  font-size: 22px;
  display: none;
  justify-content: center;
  align-items: center;
}
.leaflet-touch .leaflet-geosearch-bar form {
  border: 1px solid rgb(211, 211, 211);
  border-radius: 10px;
  overflow: hidden;
}
.leaflet-control-geosearch form {
  padding: 0;
}
.leaflet-control-geosearch .results.active {
  padding: 0;
  border: none;
}
.leaflet-touch .leaflet-geosearch-bar form input {
  height: 50px;
}
.leaflet-container {
  font: 14px Montserrat, Arial, Helvetica, sans-serif;
}
.leaflet-control-geosearch .results.active div {
  border-top: 1px solid rgb(206, 206, 206);
}

@media screen and (max-width: 760px) {
  .leaflet-control-geosearch,
  .leaflet-control-geosearch ul {
    margin: 10px 0 0 10px;
  }
  .leaflet-touch .leaflet-geosearch-bar form {
    width: calc(100vw - 30px);
    top: 0px;
  }
}
