.react-awesome-player-container {
  height: 100% !important;
}
.react-awesome-player-container {
  height: 100% !important;
  display: flex;
  align-items: center;
}
.react-awesome-player-container #vjs_video_3 {
  height: 100%;
}
.react-awesome-player-container:first-child {
  height: 100%;
}

@media screen and (max-width: 760px) {
  .video {
    width: 100%;
    height: 400px;
    border-radius: 5px;
    transition: 0.3s ease all;
  }
}

@media screen and (max-width: 620px) {
  .video {
    height: 300px;
  }
}
@media screen and (max-width: 500px) {
  .video {
    height: 250px;
  }
}
@media screen and (max-width: 360px) {
  .video {
    height: 160px;
  }
}
